@import "scss/index";
.notifications {
  position: fixed;
  z-index: $layer-modal + 1;
  margin-top: -16px;
}


@include media-mobile {

  .notifications {
    bottom: 16px;
    right: 16px;
    left: 16px;
  }
}

@include media-tablet {

 .notifications {
   bottom: 32px;
   right: 32px;
 }
}

@include media-desktop {

 .notifications {
   bottom: 36px;
   right: 36px;
 }
}
