@import "scss/index";
.techsuppComments {
  & > :not(a) {
    opacity: .32;
  }

  a {
    border-bottom: 2px solid #81abee;

    &:hover {
      background-color: #81abee;
    }
  }
}